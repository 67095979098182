import { Box, CircularProgress } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from '@/hooks/AuthContext';

export default function IndexPage() {
  const { loginStatus, user, hasPermission } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (loginStatus === 'LOGGED_OUT') {
      router.push('/login');
    } else if (loginStatus === 'LOGGED_IN') {
      switch (user.type) {
        case 'admin':
          hasPermission('reports.sensitive')
            ? router.push('/dashboard')
            : router.replace('/orders');
          break;
        case 'client':
          {
            if (user.role.canCreateOrder) router.push('/dashboard');
            else router.push('/clients/me');
          }
          break;
        case 'vendor':
          router.push('/clients');
          break;
      }
    }
  }, [loginStatus]);

  return (
    <Box
      minH='100vh'
      alignItems='center'
      justifyContent='center'
      display='flex'
    >
      <CircularProgress isIndeterminate color='orange.500' />
    </Box>
  );
}
